<template>
  <product title="Amazfit T-Rex Pro"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="1099"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    buy-link="https://item.jd.com/100018806248.html"
  ></product>
</template>

<script>
import Product from '../Product'

export default {
  name: 'Amazfit-T-Rex-Pro',
  components: {
    Product
  },
  data () {
    return {
      slogan: '100米防水｜15项军规认证｜四星三模定位',
      colors: [
        '陨石黑',
        '沙漠白',
        '湖光蓝'
      ],
      swiperData: [
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/t-rex-pro/1_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/t-rex-pro/1_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/t-rex-pro/1_pro_03.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/t-rex-pro/2_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/t-rex-pro/2_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/t-rex-pro/2_pro_03.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/t-rex-pro/3_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/t-rex-pro/3_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/t-rex-pro/3_pro_03.png'
        ]
      ],
      relativedProducts: [
      ],
      detailImages: [
        'https://site-cdn.huami.com/files/amazfit/cn/t-rex-pro/m/1.jpg',
        'https://site-cdn.huami.com/files/0/5/2/t-rex-pro-kv-mobile.png',
        'https://site-cdn.huami.com/files/amazfit/cn/t-rex-pro/m/2.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/t-rex-pro/m/3.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/t-rex-pro/m/4.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/t-rex-pro/m/5.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/t-rex-pro/m/6.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/t-rex-pro/m/7.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/t-rex-pro/m/8.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/t-rex-pro/m/9.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/t-rex-pro/m/10.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/t-rex-pro/m/11.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/t-rex-pro/m/12.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/t-rex-pro/m/13.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/t-rex-pro/m/14.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/t-rex-pro/m/15.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/t-rex-pro/m/16.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/t-rex-pro/m/17.jpg'
      ],
      paramImages: [
        'https://site-cdn.huami.com/files/amazfit/cn/t-rex-pro/m/18.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/t-rex-pro/m/19.jpg'
      ]
    }
  },
  methods: {
  }
}
</script>

<style>
</style>
